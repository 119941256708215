import { render, staticRenderFns } from "./UpdateEmail.vue?vue&type=template&id=5b5dc01f&scoped=true&"
import script from "./UpdateEmail.vue?vue&type=script&lang=ts&"
export * from "./UpdateEmail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5dc01f",
  null
  
)

export default component.exports