













import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue'),
    Enable2FA: () => import('./Enable2FA.vue'),
    Backup2FACodes: () => import('./Backup2FACodes.vue'),
    Disable2FA: () => import('./Disable2FA.vue'),
    Help2FA: () => import('./Help2FA.vue')
  }
})
export default class Manage2FA extends Vue {
  @UserModule.State currentUser
  @UserModule.Action getUser

  code = ''
  backUpCodes: string[] = []

  get userHas2FAEnabled() {
    return this.currentUser.twoFactorActivated
  }
}
