








































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ProfileBackgrounds from '@/config/ProfileBackgrounds'
import { LocalStorageManager } from '@/services/Managers'
import { Toast } from '@/mixins'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue')
  }
})
export default class CustomizationSettings extends Mixins(Toast) {
  currentBackground = ''
  selectedBackground = ''
  backgrounds = ProfileBackgrounds

  setBackground() {
    LocalStorageManager.setItem('OGG_Background', this.selectedBackground)

    this.showSuccessToast({
      title: 'Successfully updated',
      message: 'Background has been updated successfully'
    })
  }
}
