
























































































































import { Component, Mixins } from 'vue-property-decorator'
import { Account } from '@/api'
import { UserModule } from '@/store/modules'
import { FormHelper } from '@/mixins'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue')
  }
})
export default class UpdateEmail extends Mixins(FormHelper) {
  @UserModule.Action getUser

  currentEmail = ''
  newEmail = ''
  newEmailConfirmation = ''
  password = ''
  error = false
  success = false
  message = ''

  async updateEmail() {
    const emailChanged = await Account.changeEmail({
      currentEmail: this.currentEmail,
      newEmail: this.newEmail,
      newEmailConfirmation: this.newEmailConfirmation,
      password: this.password
    })

    if (emailChanged.detail) {
      this.error = true
      this.message = emailChanged.detail
    } else {
      this.success = true
      this.message = emailChanged

      await this.getUser()
    }
  }

  resetForm() {
    this.currentEmail = ''
    this.newEmail = ''
    this.newEmailConfirmation = ''
    this.password = ''
    this.error = false
  }
}
