




































import { Component, Mixins } from 'vue-property-decorator'
import Discord from '@/services/Connections/Discord'
import { IMixinConnections } from '@/types/interfaces'
import { ImageResolver } from '@/services/Managers'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue')
  }
})
export default class ConnectionSettings extends Mixins<IMixinConnections>(Discord) {
  setDefaultAvatar = ImageResolver.setDefaultAvatar
}
