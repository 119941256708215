var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('CardTitleWithIcon',{attrs:{"title":_vm.$t('EMAIL.UPDATE'),"icon":"MailIcon"}}),_c('b-card-text',{staticClass:"text-center mb-2",class:{
      'text-success': _vm.success,
      'text-danger': _vm.error
    }},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('ValidationObserver',{ref:"updateEmailForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateEmail($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL.CURRENT'),"label-for":"account-e-mail"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('EMAIL.CURRENT')},model:{value:(_vm.currentEmail),callback:function ($$v) {_vm.currentEmail=$$v},expression:"currentEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL.NEW'),"label-for":"account-new-e-mail"}},[_c('ValidationProvider',{attrs:{"name":"NewEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('EMAIL.NEW')},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('EMAIL.CONFIRM_NEW'),"label-for":"account-new-e-mail"}},[_c('ValidationProvider',{attrs:{"name":"New Email Confirmation","rules":"required|email|confirmed:NewEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('EMAIL.CONFIRM_NEW')},model:{value:(_vm.newEmailConfirmation),callback:function ($$v) {_vm.newEmailConfirmation=$$v},expression:"newEmailConfirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PASSWORD.TITLE'),"label-for":"password"}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"password","state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('SAVE_CHANGES'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('RESET'))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }