

























import { Component, Vue } from 'vue-property-decorator'
import { Account } from '@/api'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue'),
    SessionsSettingsCard: () => import('./SessionsSettingsCard.vue')
  }
})
export default class SessionsSettings extends Vue {
  isLoading = true
  devices = []

  async mounted() {
    try {
      this.devices = await this.getSessions()
      this.isLoading = false
    } catch(e) {
      console.error('Cannot get sessions', e)
    }

    this.$root.$on('refreshSessions', async() => {
      this.devices = await this.getSessions()
    })
  }

  getSessions = async() => {
    const sortDevices = (a, b) => {
      return (b.isCurrent - a.isCurrent)
          || (new Date(b.lastActivity).getTime() - new Date(a.lastActivity).getTime())
    }
  
    return (await Account.getAccountSessions()).sort(sortDevices)
  }
}
