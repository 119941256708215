

























import { Component, Vue } from 'vue-property-decorator'
import CustomizationSettings from '@/components/Settings/CustomizationSettings.vue'
import ConnectionSettings from '@/components/Settings/ConnectionSettings.vue'
import UpdateEmail from '@/components/Settings/UpdateEmail.vue'
import UpdatePassword from '@/components/Settings/UpdatePassword.vue'
import Manage2FA from '@/components/Settings/Manage2FA.vue'
import SessionsSettings from '@/components/Settings/SessionsSettings.vue'
import NotificationsSettings from '@/components/Settings/NotificationsSettings.vue'

@Component
export default class Settings extends Vue {
  settings = [
    { name: this.$t('GENERAL'), component: CustomizationSettings, icon: 'UserIcon', isActive: true },
    { name: this.$t('CONNECTIONS'), component: ConnectionSettings, icon: 'LinkIcon' },
    { name: this.$t('EMAIL.UPDATE'), component: UpdateEmail, icon: 'LockIcon' },
    { name: this.$t('PASSWORD.UPDATE'), component: UpdatePassword, icon: 'LockIcon' },
    { name: this.$t('2FA'), component: Manage2FA, icon: 'LockIcon' },
    { name: this.$t('DEVICE_ACTIVITY'), component: SessionsSettings, icon: 'SmartphoneIcon' },
    /* { name: this.$t('NOTIFICATIONS'), component: NotificationsSettings, icon: 'BellIcon' } */
  ]
}
