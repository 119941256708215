





































































































import { Component, Mixins } from 'vue-property-decorator'
import FormHelper from '@/mixins/FormHelper'
import { Account } from '@/api'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue')
  }
})
export default class UpdatePassword extends Mixins(FormHelper) {
  currentPassword = ''
  newPassword = ''
  newPasswordConfirmation = ''
  error = false
  success = false
  message = ''

  async updatePassword() {
    const passwordChanged = await Account.changePassword({
      oldPassword: this.currentPassword,
      newPassword: this.newPassword,
      newPasswordConfirmation: this.newPasswordConfirmation
    })

    if (passwordChanged.detail) {
      this.error = true
      this.message = passwordChanged.detail
    } else {
      this.success = true
    }
  }

  resetForm() {
    this.currentPassword = ''
    this.newPassword = ''
    this.newPasswordConfirmation = ''
    this.error = false
  }
}
